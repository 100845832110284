import React from "react";
import encuentraIntex from '../images/encuentra-intex.jpg';
import encuentraIntexSmall from '../images/small/encuentra-intex.jpg';
import separadador from '../images/separador.png';

export default function Grid() {
    return (
        <div className='grid'>
            <div className='item'>
                <div className='text'>
                    <p className='title'>ESCUCHA <br/>A CARLOS SOBERA</p>
                    <p className="texto">y monta tu piscina INTEX para <br /> disfrutar del verano.</p>
                </div>
            </div>
            <div className='item'>
                <picture>
                    <source srcSet={encuentraIntexSmall} media="(max-width: 420px)" />
                    <img src={encuentraIntex} className="responsiva" alt="Encuentra tu nueva piscina Intex"/>
                </picture>
                <div className='text-encuentra'>
                    <p className="textoBlanco">Encuentra tu nueva piscina INTEX en <br /> hipermercados, centros de bricolaje, <br/> tiendas especializadas y comercios <br/> convencionales.</p>
                </div>
            </div>
            <div className='separador-item'>
            <picture>
                <img src={separadador} alt="separador"/>
            </picture>
            </div>
        </div>
    );
}