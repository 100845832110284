import React from "react";
import piscinasHinchables from '../images/piscinas-hinchables.jpg';
import piscinasHinchablesSmall from '../images/small/piscinas-hinchables.jpg';

export default function Grid() {
    return (
        <div className='grid-3'>

            <div className='item-left'>
                <div className='text'>
                    <p className='title'>PISCINAS <br/>HINCHABLES</p>
                    <p className="texto">Lona triple capa<br />SuperTough® extrarresistente.<br/>Estructura de pieza única para un montaje <br/> aún más rápido.</p>
                </div>
            </div>
            <div className='item-right'>
                <picture>
                    <source srcSet={piscinasHinchablesSmall} media="(max-width: 420px)" />
                    <img src={piscinasHinchables} className="responsiva" alt="Piscinas Hichables"/>
                </picture>
            </div>
        </div>
    );
}