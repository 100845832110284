import React, {lazy, Suspense} from 'react';
import './sass/main.scss';

import Loading from './components/loading';
import HeaderPiscinas from './components/header-piscinas';
import Grid1 from './components/grid-1';
import Grid2 from './components/grid-2';
import Grid3 from './components/grid-3';
import Grid4 from './components/grid-4';
import Grid5 from './components/grid-5';
import somos_verano from './images/somos-verano.jpg';
const Revi = lazy(() => import('./components/revi'));

function App() {
  return (
      <div className="App">
      <Suspense fallback={<Loading />}>
      <HeaderPiscinas />
      <Grid1 />
      <Grid2 />
      <Grid3 />
      <Grid4 />
      <Grid5 />
      <div className='separador footer'>
          <picture>
            <img src={somos_verano} className="somosverano" alt="Somos el verano"/>
          </picture>
          <p>Con la garantía de INTEX</p>
      </div>
      <div className='footer-app'>
        <Revi />
        <p>&copy; INTEX Todos los derechos reservados</p>
      </div>
      </Suspense>
    </div>
  );
}

export default App;
