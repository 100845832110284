import React from "react";
import piscinasInfantiles from '../images/piscinas-infantiles.jpg';
import piscinasInfantilesSmall from '../images/small/piscinas-infantiles.jpg';
import separadador from '../images/separador.png';

export default function Grid() {
    return (
        <div className='grid-4'>
            <div className='item-left'>
                <picture>
                    <source srcSet={piscinasInfantilesSmall} media="(max-width: 420px)" />
                    <img src={piscinasInfantiles} className="responsiva" alt="Piscinas Infantiles"/>
                </picture>
            </div>
            <div className='item-right'>
                <div className='text'>
                    <p className='title'>PISCINAS <br/>INFANTILES</p>
                    <p className="texto">Diseños originales y llamativos.<br />Variedad de formatos con<br/>actividades y juegos.</p>
                </div>
            </div>
            <div className='separador-item'>
            <picture>
                <img src={separadador} alt="separador"/>
            </picture>
            </div>
        </div>
    );
}