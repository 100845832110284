import React from "react";
import piscinasDesmontables from '../images/piscinas-desmontables.jpg';
import piscinasDesmontablesMedium from '../images/medium/piscinas-desmontables.jpg';
import piscinasDesmontablesSmall from '../images/small/piscinas-desmontables.jpg';
import separadador from '../images/separador.png';

export default function Grid() {
    return (
        <div className='grid-2'>
            <div className='item-left'>
                <picture>
                    <source srcSet={piscinasDesmontablesSmall} media="(max-width: 420px)" />
                    <source srcSet={piscinasDesmontablesMedium} media="(max-width: 600px)" />
                    <img src={piscinasDesmontables} className="responsiva" alt="Encuentra tu nueva piscina Intex"/>
                </picture>
            </div>
            <div className='item-right'>
                <div className='text'>
                    <p className='title'>PISCINAS <br/>DESMONTABLES</p>
                    <p className="texto">Lona triple capa<br />SuperTough extrarresistente.<br/>Estructura de fácil montaje en minutos.</p>
                </div>
            </div>
            <div className='separador-item'>
            <picture>
                <img src={separadador} alt="separador"/>
            </picture>
            </div>
        </div>
    );
}